import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Press 4×8\\@75% 1RM`}</p>
    <p>{`Barbell Rows 4×8\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`25/20 Cal Assault Bike`}</p>
    <p>{`20 S2OH (115/75)`}</p>
    <p>{`25/20 Cal Row`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Exciting news!  Work on our back lot is underway! You will not be
able to use the Washington Street entrance.  Get ready for some outdoor
wods soon!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Reminder:  Bootcamp class tomorrow at 9:30. Bootcamp classess are
free for non members the rest of this month so spread the word.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Evening CrossFit classes are now offered Monday-Friday at 4:00,
5:00 & 6:00pm.`}</em></strong>{` `}<strong parentName="p">{`*   *`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      